import React from 'react'
import { showBlogInfo } from '../../../helper'

const data = [
    {
        title : 'GRANT OF LICENSE',
        subs : [
            {
                sub1 : 'By installing, downloading and otherwise using the Software and the IP You agree to become the Licensee and be granted limited, non-exclusive, non-sublicensable, worldwide, non-assignable, non-exclusive and royalty-free license to use the Software and the IP. Licensor grants You the following rights provided that You comply with all terms and conditions of this EULA:',
                sub2 : []
            },
            {
                sub1 : 'You may download, install, use, access, display and run one copy of the Software only as an end user of the Technology Platform. The Software shall remain and be used within the Technology Platform as its functional part; You shall use the IP only as an end user of the Technology Platform and only for the purposes of exploiting the Technology Platform and its functions.',
                sub2 : []
            },
        ]
    },
    {
        title : 'RESERVATION OF RIGHTS AND OWNERSHIP',
        subs : [
            {
                sub1 : 'The Software and the IP are protected by copyright, patent, registration and other intellectual property regulations. Licensor or its suppliers own the title, copyright and other intellectual property rights in the Software and the IP. The Software and the IP are licensed, not sold.',
                sub2 : []
            },
            {
                sub1 : 'You agree that Licensor and its affiliates may collect and use (including through any applications) technical information for the purpose of the Technology Platform support services related to the Software and the IP.',
                sub2 : []
            },
        ]
    },
    {
        title : 'LIMITATION ON END USER RIGHTS',
        subs : [
            {
                sub1 : 'You may not reverse engineer, decompile, disassemble, otherwise attempt to discover the source code or algorithms of the Software, adapt, modify or alter otherwise the IP or the Software and any part thereof, disable any features of the Software, create derivative works based on the Software or the IP, make back-up copies, register the Software, the IP or any part thereof or use the Software, the IP or any part thereof for commercial purposes, including without limitation deriving profit.',
                sub2 : []
            },
            {
                sub1 : 'You may not sell or provide licenses to or disseminate the Software or its parts in any other ways to any third person.',
                sub2 : []
            },
            {
                sub1 : 'You are not allowed to decompile, disassemble, or modify the IP or use any parts of it for copying, getting commercial gain or for incorporation into any other platforms but the Technology Platform.\nFailure to comply with restrictions specified in this EULA may lead to civil, administrative or criminal liability under applicable law.',
                sub2 : []
            },
        ]
    },
    {
        title : 'SOFTWARE UPDATES',
        subs : [
            {
                sub1 : 'Licensor may provide to You or make available to You updates, upgrades, supplements and add-on components (if any) of the Software, including bug fixes, service upgrades (partly or entire), products or devices, and updates, and enhancements to any Software previously installed (including entirely new versions), (collectively “Update”) after the date You obtain Your initial copy of the Software in order to improve such Software or ultimately enhance Your user experience with the Technology Platform. This EULA applies to all and any component of the Update that Licensor may provide to You or make available to You after You obtain Your initial copy of the Software, unless we provide other terms along with such Update.',
                sub2 : []
            },
            {
                sub1 : 'To use the Software provided through Update, You must first be licensed for the Software identified by Licensor as eligible for the Update. The updated Software version may add new functions and, in some limited cases, may delete existing functions.',
                sub2 : []
            },
        ]
    },
    {
        title : 'NO SOFTWARE TRANSFER',
        subs : [
            {
                sub1 : 'You may not transfer or assign this EULA or the rights to the Software and the IP granted herein to any third party.',
                sub2 : []
            },
        ]
    },
    {
        title : 'TERM',
        subs : [
            {
                sub1 : 'This EULA is effective for the period the Technology Platform, the IP and the Software are being used by You.',
                sub2 : []
            },
            {
                sub1 : 'This EULA terminates in case:\na. You breach the EULA; or\nb. You decide to terminate Your use of the Technology Platform, or \nc. the Technology Platform ceases to exist.',
                sub2 : []
            },
        ]
    },
    {
        title : 'DISCLAIMER OF WARRANTY',
        subs : [
            {
                sub1 : 'UNLESS SEPARATELY STATED ALL SOFTWARE AND/OR IP PROVIDED BY LICENSOR WITH THE TECHNOLOGY PLATFORM (WHETHER INCLUDED WITH THE TECHNOLOGY PLATFORM, DOWNLOADED, OR OTHERWISE OBTAINED) IS PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND FROM LICENSOR, EITHER EXPRESS OR IMPLIED. TO THE FULLEST POSSIBLE EXTENT PURSUANT TO APPLICABLE LAW, LICENSOR DISCLAIMS ALL WARRANTIES EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY OR WORKMANSHIP LIKE EFFORT, FITNESS FOR A PARTICULAR PURPOSE, RELIABILITY OR AVAILABILITY, ACCURACY, LACK OF VIRUSES, QUIET ENJOYMENT, NON INFRINGEMENT OF THIRD PARTY RIGHTS OR OTHER VIOLATIONS OF RIGHTS. SOME JURISDICTIONS DO NOT ALLOW EXCLUSIONS OR LIMITATIONS OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM LICENSOR OR ITS AFFILIATES SHALL BE DEEMED TO ALTER THIS DISCLAIMER BY LICENSOR OF WARRANTY REGARDING THE SOFTWARE AND/OR IP OR EULA, OR TO CREATE ANY WARRANTY OF ANY SORT FROM LICENSOR.',
                sub2 : []
            },
            {
                sub1 : 'LICENSOR DISCLAIMS ANY RESPONSIBILITY FOR ANY DISCLOSURE OF INFORMATION OR ANY OTHER PRACTICES OF ANY THIRD-PARTY APPLICATION PROVIDER. LICENSOR EXPRESSLY DISCLAIMS ANY WARRANTY REGARDING WHETHER YOUR PERSONAL INFORMATION IS CAPTURED BY ANY THIRD-PARTY APPLICATION PROVIDER OR THE USE TO WHICH SUCH PERSONAL INFORMATION MAY BE PUT BY SUCH THIRD-PARTY APPLICATION PROVIDER.',
                sub2 : []
            },
        ]
    },
    {
        title : 'LIMITATION OF LIABILITY',
        subs : [
            {
                sub1 : 'LICENSOR WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF OR RELATING TO THE USE OR THE INABILITY TO USE THE SOFTWARE AND/OR IP OR COMBINE THE SOFTWARE WITH ANY THIRD PARTY APPLICATION, ITS CONTENT OR FUNCTIONALITY, INCLUDING BUT NOT LIMITED TO DAMAGES CAUSED BY OR RELATED TO ERRORS, OMISSIONS, INTERRUPTIONS, DEFECTS, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, FAILURE TO CONNECT, NETWORK CHARGES, IN-APP PURCHASES, AND ALL OTHER DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU. NOTWITHSTANDING THE FOREGOING, LICENSOR TOTAL LIABILITY TO YOU FOR ALL LOSSES, DAMAGES, CAUSES OF ACTION, INCLUDING BUT NOT LIMITED TO THOSE BASED ON CONTRACT, TORT, OR OTHERWISE, ARISING OUT OF YOUR USE OF THE SOFTWARE AND/OR IP ON THIS TECHNOLOGY PLATFORM, OR ANY OTHER PROVISION OF THIS EULA, SHALL NOT EXCEED THE AMOUNT OF 100 USD. THE FOREGOING LIMITATIONS, EXCLUSIONS, AND DISCLAIMERS SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EVEN IF ANY REMEDY FAILS ITS ESSENTIAL PURPOSE.',
                sub2 : []
            },
        ]
    },
    {
        title : 'APPLICABLE LAW; ARBITRATION',
        subs : [
            {
                sub1 : 'You and Licensor agree to arbitrate any dispute arising from these EULA. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL.',
                sub2 : []
            },
            {
                sub1 : 'You and Licensor agree to notify each other in writing of any dispute within thirty (30) days of when it arises. Notice to Licensor shall be sent to legal@tobtc.io',
                sub2 : []
            },
            {
                sub1 : 'Any dispute, controversy, difference or claim arising out of or relating to the Terms, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it shall be referred to and finally resolved by arbitration.\nThe number of arbitrators shall be three. The arbitration proceedings shall be conducted in English language.',
                sub2 : []
            },
            {
                sub1 : 'Any dispute between the parties arising hereof shall be governed by the laws, without giving effect to any conflict of laws principles that may provide for the application of the law of another jurisdiction. Whether the dispute is heard in arbitration or in court, You and Licensor will not commence against the other a class action, class arbitration or representative action or proceeding.',
                sub2 : []
            },
        ]
    },
    {
        title : 'ENTIRE AGREEMENT; SEVERABILITY',
        subs : [
            {
                sub1 : 'This EULA is the entire agreement between You and Licensor relating to the Software and the IP and supersedes all prior or contemporaneous oral or written communications, proposals and representations with respect to the Software and the IP or any other subject matter covered by this EULA. If any provision of this EULA is held to be void, invalid, unenforceable or illegal, the other provisions shall continue in full force and effect.',
                sub2 : []
            },
        ]
    },
    {
        title : 'INJUNCTIVE RELIEF',
        subs : [
            {
                sub1 : 'The Licensee acknowledges and agrees that the breach by it of any obligations hereunder may cause serious and irreparable harm to Licensor, which probably could not adequately be compensated for in damages. Licensee therefore consents to an order of specific performance or an order of injunctive relief being issued against Licensee restraining it from any further breach of such provisions and agrees that Licensor may issue such injunction against it without the necessity of an undertaking as to damages. The provisions of this section shall not derogate from any other remedy, which Licensor may have in the event of such a breach.',
                sub2 : []
            },
        ]
    },
]

function EULA() {
  return (
    <div className="nk-wrap">
        <header className="nk-header" id="privacy-policy">
            <div className="header-banner section">
                <div className="nk-banner">
                    <div className="banner banner-page">
                        <div className="banner-wrap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-9">
                                        <div className="banner-caption cpn tc-light text-center">
                                            <div className="cpn-head">
                                                <h2 className="title ttu">End User License Agreement (EULA)</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm shape-a-sm"></div>
            </div>
        </header>
        <main className="nk-pages">
            <section className="section bg-white">
                <div className="container">
                    <div className="nk-block nk-block-blog">
                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-8 col-lg-10">
                                            <div className='blog-content'>
                                                {showBlogInfo(data)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
  )
}

export default EULA