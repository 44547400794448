import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { livePriceReducer } from './livePriceSlice';
import mainReducer from './mainSlice';

const rootReducer = combineReducers({
  livePrice: livePriceReducer,
  mainReducer: mainReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
