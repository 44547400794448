import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from './Screen/Laqira/Home';
import AboutUs from './Screen/Laqira/AboutUs';
import Navbar from './Component/Laqira/Navbar';
import Footer from './Component/Laqira/Footer';
import EULA from './Screen/Laqira/EULA';
import PrivacyPolicy from './Screen/Laqira/PrivacyPolicy';
import Terms from './Screen/Laqira/Terms';
import ErrorPage from './Component/Auth/ErrorPage';
import Announcement from './Screen/Laqira/Announcement';
import ChangeLogPage from './Screen/Laqira/ChangeLogPage';
import ContactUs from './Screen/Laqira/ContactUs';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HowUse from './Screen/Laqira/HowUse';
import Blogs from './Screen/Laqira/Blogs';

function Router() {
  const baseUrl = process.env.PUBLIC_URL;
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div id="mainDiv" className="">
      <Navbar/>
        <Routes>
          <Route exact path={baseUrl} element={<Home />} />
          <Route exact path={baseUrl + '/about-us'} element={<AboutUs />} />
          <Route exact path={baseUrl + '/end-user-license-agreement-eula'} element={<EULA/>} />
          <Route exact path={baseUrl + '/privacy-policy'} element={<PrivacyPolicy/>} />
          <Route exact path={baseUrl + '/terms-and-conditions'} element={<Terms/>} />
          <Route exact path={baseUrl + '/announcements'} element={<Announcement/>} />
          <Route exact path={baseUrl + '/changelog'} element={<ChangeLogPage/>} />
          <Route exact path={baseUrl + '/contact-us'} element={<ContactUs/>} />
          <Route exact path={baseUrl + '/blogs/:id'} element={<Blogs/>} />
          <Route exact path='*' element={<ErrorPage/>} />
        </Routes>
      <Footer/>
    </div>
  )
}

export default Router