import React from 'react'

function DownloadApp({background}) {
  return (
<div className={`section market-trend ${background}`} id="anywhere">
    <div className="container">
        <div className="row mb-5">
            <div className="col-xl-7 col-lg-10 col-md-12" data-aos="fade-up" data-aos-duration="700">
                <h2 className="h1">Trade <span className="text-primary">Anywhere.</span></h2> 
                <p className="lead">Compatible with multiple devices, start trading with safety and convenience.</p>
            </div>
        </div>
        <div className="row g-3 align-items-center pt-2">
            <div className="col-xl-8" data-aos="fade-up" data-aos-duration="1000">
                <img src="assets/images/dhashbord.png" alt="#" className="img-fluid" />
            </div>
            <div className="col-xl-4" data-aos="fade-up" data-aos-duration="1500">
                <div className="download-app text-center">
                    <img src="assets/images/qr-code.png" alt="#" className="img-fluid" />
                    <h4 className="px-2 mt-2">Scan Download App</h4>
                </div>
                <div className="row g-4 mt-4 row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
                    <div className="col text-center">
                        <i className="fa fa-apple fs-2 chart-text-color5" aria-hidden="true"></i>
                        <span className="d-block">App Store</span>
                    </div>
                    <div className="col text-center">
                        <i className="fa fa-google fs-2 chart-text-color5" aria-hidden="true"></i>
                        <span className="d-block">Google Play</span>
                    </div>
                    <div className="col text-center">
                        <i className="fa fa-windows fs-2 chart-text-color5" aria-hidden="true"></i>
                        <span className="d-block">Browser</span>
                    </div>
                    {/* <div className="col text-center">
                        <i className="fa fa-android fs-2 chart-text-color5" aria-hidden="true"></i>
                        <span className="d-block">Android APK</span>
                    </div>
                    <div className="col text-center">
                        <i className="fa fa-linux fs-2 chart-text-color5" aria-hidden="true"></i>
                        <span className="d-block">Linux</span>
                    </div>
                    <div className="col text-center">
                        <i className="fa fa-plug fs-2 chart-text-color5" aria-hidden="true"></i>
                        <span className="d-block">API</span>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default DownloadApp