import React from 'react'

function AboutUs() {
  return (
    <div class="nk-wrap">
        <header class="nk-header" id="header">

            <div class="header-banner section">
                <div class="nk-banner">
                    <div class="banner banner-page">
                        <div class="banner-wrap">
                            <div class="container">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-xl-6 col-lg-9">
                                        <div class="banner-caption cpn tc-light text-center">
                                            <div class="cpn-head">
                                                <h2 class="title ttu">Laqira Protocol | About Us</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nk-ovm shape-c">
                </div>
            </div>
        </header>


            <main class="nk-pages bg-white">
                <section class="section pb-0 ">
                    <div class="container">
                        <div class="nk-block">
                            <div class="section-head text-left">
                                <h2 class="title">Founder</h2>
                            </div>
                            <div class="row gutter-vr-15px justify-content-center">
                                <div class="col-lg-12 col-mb-10">
                                    <div class="nk-block-text">
                                        <p><b class="text-primary title-bold">Sina Osivand </b> is the founder of <a href="https://laqira.io" target="_blank" rel="noopener noreferrer" class="text-primary title-bold">Laqira Protocol </a>and CEO of <a href="https://tobtc.io" target="_blank" rel="noopener noreferrer" class="text-primary title-bold">TOBTC Trading Company</a>. He started his journey in the world of finance back in 2009, working in the FX market. He was responsible for many successful startups. In 2016, he started working in the field of cryptocurrencies. He established <a href="https://tobtc.io" target="_blank" rel="noopener noreferrer" class="text-primary title-bold">TOBTC family</a> in 2016 as a technical analysis and crypto news station channel. Also, TOBTC community has become a place for discussing the crypto and blockchain world with the audience over time. After several years of experience in the field of cryptocurrencies, he created a professional team and founded <a href="https://tobtc.io" target="_blank" rel="noopener noreferrer" class="text-primary title-bold">TOBTC TRADING Company</a>. In 2021 he launched <a href="https://laqira.io" target="_blank" rel="noopener noreferrer" class="text-primary title-bold">Laqira Protocol</a> project in order to follow innovative blockchain-based ideas. His passion has always been to improve blockchain and develop new technologies.</p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section pb-0 ">
                    <div class="container">
                        <div class="nk-block">
                            <div class="section-head text-left">
                                <h2 class="title">Our Mission</h2>
                            </div>
                            <div class="row gutter-vr-15px justify-content-center">
                                <div class="col-lg-12 col-mb-10">
                                    <div class="nk-block-text">
                                        <p>Laqira Protocol is a project aiming to create a blockchain-based super application providing innovative technologies and services in the crypto space. This ecosystem will grow based on the community’s wishes and proposals as LQR (the native currency of the Laqira Protocol project) is a governance token using which each member of the community can create his/her own proposals and other members can vote for or against them in Laqira Protocol’s governance system. For more information you can refer to the official website:</p>
                                        <a href="https://laqira.io" target="_blank" rel="noopener noreferrer"  class="btn btn-primary rounded py-2 fs-7"><span>Laqira Protocol</span></a>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section pb-0 ">
                    <div class="container">
                        <div class="nk-block">
                            <div class="section-head text-left">
                                <h2 class="title">Our Products | Laqira Exchange</h2>
                            </div>
                            <div class="row gutter-vr-15px justify-content-center">
                                <div class="col-lg-12 col-mb-10">
                                    <div class="nk-block-text">
                                        <p>Laqira Exchange is a centralized cryptocurrency trading platform with +1.5 billion dollars in SPOT daily volume and +120 trading assets in SPOT and Futures (up to 100x) markets. People can invite their friends to the platform using their referral link to earn a part of the trading commission that their invitees pay to the exchange in each trade on the SPOT market. Also by holding LQR, users can decrease the commission that they pay to the exchange for each trade in the SPOT market.</p>
                                        <a href="https://laqira.com" target="_blank" rel="noopener noreferrer"  class="btn btn-primary rounded py-2 fs-7"><span>Laqira Exchange</span></a>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section pb-0 ">
                    <div class="container">
                        <div class="nk-block">
                            <div class="section-head text-left">
                                <h2 class="title">Our Products | Laqirace</h2>
                            </div>
                            <div class="row gutter-vr-15px justify-content-center">
                                <div class="col-lg-12 col-mb-10">
                                    <div class="nk-block-text">
                                        <p>Laqirace is the First Ever Drive2Earn metaverse game on Binance Smart Chain with an innovative monetization and financial system based on BUSD and LQR. For more information about the game, one can refer to the official website of Lqairace at:</p>
                                        <div>
                                            <a href="https://laqirace.com" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>Laqirace Game</span></a>
                                            <a href="https://laqirace.com/pages/white_paper/v1/Laqirace.pdf" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>Whitepaper</span></a>
                                            <a href="https://play.google.com/store/apps/details?id=com.Laqira.Laqirace&hl=en&gl=US" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>Android</span></a>
                                            <a href="https://laqira.com" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>iOS</span></a>
                                            <a href="https://laqira.com" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>Desktop</span></a>
                                        </div>
                                      
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section pb-0 ">
                    <div class="container">
                        <div class="nk-block">
                            <div class="section-head text-left">
                                <h2 class="title">Our Products | Laqira NFT Marketplace</h2>
                            </div>
                            <div class="row gutter-vr-15px justify-content-center">
                                <div class="col-lg-12 col-mb-10">
                                    <div class="nk-block-text">
                                        <p>This means that all of the functions have been implemented on the blockchain. In this marketplace, people can mint their public NFTs and put them for sale both in direct or auction modes. An optimized syncing algorithm has been implemented to sync on-chain and off-chain resources so that further functionalities such as elastic search and fetching fast information have been enabled on the marketplace. Also, the marketplace has been equipped with an optimized 3D engine using which it can render and build live 3D models.Laqira Protocol NFT Marketplace is a fully decentralized marketplace at:</p>
                                        <div>
                                            <a href="https://marketplace.laqira.io" target="_blank" rel="noopener noreferrer"  class="btn btn-primary rounded py-2 fs-7 "><span>NFT marketplace</span></a>
                                            <a href="https://github.com/slowmist/Knowledge-Base/blob/master/open-report-V2/smart-contract/SlowMist%20Audit%20Report%20-%20Laqira%20NFT%20marketplace_en-us.pdf" target="_blank" rel="noopener noreferrer"  class="btn btn-primary rounded py-2 fs-7"><span>NFT Marketplace Security Audit</span></a>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </section>


                <section class="section pb-0 ">
                    <div class="container">
                        <div class="nk-block">
                            <div class="section-head text-left">
                                <h2 class="title">Our Products | Laqira Token (LQR)</h2>
                            </div>
                            <div class="row gutter-vr-15px justify-content-center">
                                <div class="col-lg-12 col-mb-10">
                                    <div class="nk-block-text">
                                        <p>An ERC20 token with compound functionalities that supports governance and ERC677 (On Token Transfer) abilities.</p>
                                        <div>
                                        <a href="https://marketplace.laqira.io" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>NFT marketplace</span></a>
                                        <a href="https://github.com/slowmist/Knowledge-Base/blob/master/open-report-V2/smart-contract/SlowMist%20Audit%20Report%20-%20LaqiraToken_en-us.pdf" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>Token Security Audit</span></a>
                                        <a href="https://laqira.io/whitepaper.pdf" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>Whitepaper</span></a>
                                        <a href="https://bscscan.com/token/0xbc81ea817b579ec0334bca8e65e436b7cb540147" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>BSC Scan</span></a>
                                        <a href="https://github.com/LaqiraProtocol/LaqiraToken" target="_blank" rel="noopener noreferrer"  class=" btn btn-primary rounded py-2 fs-7"><span>Github</span></a>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section pb-0 ">
                    <div class="container">
                        <div class="nk-block">
                            <div class="section-head text-left">
                                <h2 class="title">Our Products | Laqira Time Lock Governance</h2>
                            </div>
                            <div class="row gutter-vr-15px justify-content-center">
                                <div class="col-lg-12 col-mb-10">
                                    <div class="nk-block-text">
                                        <p>A platform for creating proposal for token holders to organize further developments and operations on the project, so other token owners can vote for or against the proposal. If the votes of a proposal by other token holders reaches to a quorum and for votes are more than against ones, the proposal will be approved and go under development, otherwise the proposal will be rejected. This keeps the whole ecosystem up to date based on community’s favor.</p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </section>



                <section class="section pb-0 ">
                    <div class="container">
                        <div class="nk-block">
                            <div class="section-head text-left">
                                <h2 class="title">Our Products | Laqira Lab</h2>
                            </div>
                            <div class="row gutter-vr-15px justify-content-center">
                                <div class="col-lg-12 col-mb-10">
                                    <div class="nk-block-text">
                                        <p>Laqira lab is consisted of a research and development team concentrating on exploring new methods and inventing innovative decentralized products and technologies. Soon people will hear more about Laqira Lab as the subjects which are currently being investigated in this section, are secret and they have not yet been published.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>





                <section class="section pb-0 ">
                    <div class="container">
                        <div class="nk-block">
                            <div class="section-head text-left">
                                <h2 class="title">Conclusion</h2>
                            </div>
                            <div class="row gutter-vr-15px justify-content-center">
                                <div class="col-lg-12 col-mb-10">
                                    <div class="nk-block-text">
                                        <p> Laqira exchange is the official crypto exchange of Laqira Protocol Company that was launched on 12th Nov. 2022.

                                            Laqira Exchange provides one of the best SPOT and Futures markets up to 100x in which +120 crypto assets can be traded against USDT, BTC and ETH.
                                            
                                            Daily volume of Laqira exchange is more than 2 billion dollars with an excellent market depth and an massive liquidity on both bid and ask sides.
                                            
                                            LQR, the native currency of the Laqira Protocol's ecosystem works as an exchange asset and holding LQR in users' account results into decrease in trade commissions.
                                            
                                            Also Laqira exchange provides many more useful features for the users such as high performance API-based services for even normal users up to 300 requests per second, various networks for deposit and withdrawal, possibility of creating unlimited subaccounts, referral program and distributing referral share to inviters for both SPOT and FUTURES trades, 24/7 online support, etc.
                                            
                                            Laqira Protocol is a thousand piece puzzle project aiming to provide the most popular blockchain-based services as a super application. Laqira Protocol Lab is the R&D section of Laqira Protocol company . Innovative products of Laqira Protocol Lab include NFT, Marketplace, Crypto Exchange, Laqirace (Game and Metaverse), etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section pb-0">
                    <div class="container">
                        <div class="nk-block">
                        </div>
                    </div>
                </section>
              
            </main>
        </div>
  )
}

export default AboutUs