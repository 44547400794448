import React from 'react'
import DownloadApp from '../../../Component/Laqira/Home/DownloadApp'
import ProductsView from '../../../Component/Laqira/Home/ProductsView'
import Hero from '../../../Component/Laqira/Home/Hero'
import NeedHelp from '../../../Component/Laqira/Home/NeedHelp'
import Partners from '../../../Component/Laqira/Home/Partners'
import Features from '../../../Component/Laqira/Home/Features'
import { setBackground } from '../../../helper'
import Video from '../../../Component/Laqira/Home/Video'
import LivePrice from '../../../Component/Laqira/Home/LivePrice'
import GetStart from '../../../Component/Laqira/Home/GetStart'
import Docs from '../../../Component/Laqira/Home/Docs'
import Blog from '../../../Component/Laqira/Home/Blog'

// TO DO :
// [X] Media query (more responsive)
// [x] Redesign ProductsView section
// [X] Work on buttons shape 
// [X] Redesign Hero section
// [X] Choose the right Font
// [X] Delete all inline styles in componetns and add to main css or make define them as a const 
// [] Add chart to LivePrice
// [] Transfer data from previous template
// [] Make components more reusable
// [] More harmony in animations



function Home() {
  return (
    <>
        <Hero />
        <GetStart background={setBackground()}/>
        <ProductsView background={setBackground()}/>
        <LivePrice background={setBackground()}/>
        <Video background={setBackground()}/>
        <Features background={setBackground()}/>
        <Docs background={setBackground()}/>
        <Blog background={setBackground()}/>
        <DownloadApp background={setBackground()}/>
        <Partners background={setBackground()}/>
        <NeedHelp background={setBackground()}/>
    </>
)}

export default Home