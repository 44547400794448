import { split } from "lodash"
import React,{ Fragment } from "react"

let counter = 0
export const setBackground = () => {
    const theme = isOdd(counter) ? 'bg-light' : 'bg-white'
    counter++
    return theme
}

export const isOdd = number => {
    const state = number%2 === 1 ? true : false
    return state
}

export const showText = (data,isNormal) => {
    return(
        split(data ,"\n").map((text ,index) => {
            return(
                <Fragment key={index}>
                    <>{text}</>
                    {isNormal ? <br/> :<br  className="d-none d-md-block"/>}
                </Fragment>
            )
        })
    )
}

export const showBlogInfo = data => {
    return (
      data.map((datum, i) => {
        const index = ++i
        return (
          <div style={{marginBottom: '35px'}}>
            <h3 style={{marginBottom: '15px' ,marginTOp: '15px'}}>{datum.title}</h3>
            {datum.subs.map((subs, i1) => {
              const sub1Index = ++i1
              const sub2 = subs.sub2;
              return (
                <Fragment>
                  <p key={`${index}-${sub1Index}`} className="sub-1" >
                    <strong>{`${index}.${sub1Index}`}</strong> {showText(subs.sub1,true)}
                  </p>
                  {sub2.map((text, i2) => {
                    const sub2Index = ++i2
                    return (
                      <p key={`${index}-${sub1Index}-${sub2Index}`} className="sub-2">
                        <strong>{`${index}.${sub1Index}.${sub2Index}`}</strong> {showText(text.text,true)}
                      </p>
                    )
                  })}
                </Fragment>
              )
            })}
          </div>
        )
      })
    )
  }