import React, { useEffect, useLayoutEffect } from 'react'
import { useParams } from 'react-router'

// const data = [
//     title = 'WHAT IS TOKEN ECONOMICS OR TOKENOMICS?',
//     date = '12 Mar, 2022',
//     category = 'Blockchain',
//     img = '',
//     text = {

//     }

// ]

function Blogs() {
    const { id } = useParams()
  return (
    <div className="nk-wrap">
        <header class="nk-header" id="header">
            <div class="header-banner section">
                <div class="nk-banner">
                    <div class="banner banner-page">
                        <div class="banner-wrap">
                            <div class="container">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-xl-6 col-lg-9">
                                        <div class="banner-caption cpn tc-light text-center">
                                            <div class="cpn-head">
                                                <h2 class="title ttu">What is Token Economics or Tokenomics?</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nk-ovm shape-c">
                </div>
            </div>
        </header>
        <main className="nk-pages">
            <section className="section bg-white">
                <div className="container">
                    <div className="nk-block nk-block-blog">
                        <div className="row">
                            <div className="col-12">
                                <div className="blog-details">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-8 col-lg-10">
                                            <div className="blog-featured">
                                                <img className="round" src={"/assets/images/blog/1.jpg"} alt="featured" />
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-10">
                                            <ul className="blog-meta">
                                                <li><a href="#">12 Mar, 2022</a></li>
                                                <li><a href="#">Blockchain</a></li>
                                            </ul>
                                            <div className="blog-content">
                                                <h2>The profit motive, an important factor in economics</h2>
                                                <p>The common theory of microeconomics assumes that the ultimate goal of any business or investment is to make money. In other words, the reason for the existence of a business or investment is profitability. The profit motive is a key principle in rational choice theory. According to this theory, economic factors seek to achieve the goals that will benefit them the most. Based on this, businesses seek their interests and/or their shareholders by maximizing their profits.</p>
                                                <p>It is impossible for a person who operates in digital markets and cryptocurrencies to continue operating with the objective of making a realized loss.  So in this market, people come and invest to earn profit in a certain period of time.  Now the question that arises here is how the token of a blockchain project is valued and in which case the value of a project increases over time.</p>
                                                <h3>Which factors create credibility for a token of a blockchain-based project?</h3>
                                                <p>Which factors create credibility for a token of a blockchain-based project?</p>
                                                <p>The validity of a project creates value in the native token of that project.  The value is usually calculated and exchanged with the US dollar in the markets.  For example, $20,000 bitcoin means that each bitcoin unit is worth 20,000 US dollars.  Next, we want to examine the factors that create, increase or decrease this value:</p>
                                                <ul >
                                                    <li >
                                                        <h6 style={{display: "inline"}}>Project Validity:</h6>
                                                        <p style={{display: "inline"}}> The validity of a project depends on the people who have participated in that project and are influential, the goals and the initial idea, and sometimes advertisements.  For example, the main reason for the price peak of Dogecoin in 2021 is the advertising of Elon Musk, the richest man in the world, for this token, otherwise, this token itself has no inherent validity.</p>
                                                    </li>
                                                    <li>
                                                        <h6 style={{display: "inline"}}>Token Usage:</h6>
                                                        <p style={{display: "inline"}}> Token use is the most important factor that investors should pay attention to.  Using the token in a project increases trust in the token for investors and makes it more understandable.  For example, the Link token, which is the native token of the Chainlink project, is used by users to pay expenses within the network.  The Chainlink project, which is in the category of Oracle projects, is used to provide APIs for other projects in various fields, such as Defi, and the use of blockchain in the real world, such as the purchase and sale of carbon credits, in order to reduce the impact of greenhouse gases.</p>
                                                    </li>
                                                    <li>
                                                        <h6 style={{display: "inline"}}>Token Inflation:</h6>
                                                        <p style={{display: "inline"}}> Token inflation is also very important.  Mint tokens without correct mathematical and economic basis and outside the framework and without purpose, as well as reducing the liquidity and value of assets supporting the network, causing inflation in the network.  Token inflation, like inflation in fiat currencies, causes the value of the token to decrease over time.</p>
                                                    </li>
                                                </ul>
                                                <p>We examined the various reasons for validating the token of a project.  Important reasons that should be considered before investing in any project.  Undoubtedly, examining these matters reduces the investment risk for every investor and creates real profit in the medium term. Now we can examine and introduce the types of Tokenomic models used by blockchain projects.</p>
                                                <h3>Deflationary model:</h3>
                                                <p>In this model, a limited number of tokens are produced and offered in the network.  No matter how much the demand for the token increases, there will be no change in its supply.  For example, Bitcoin uses this model and its supply is limited to 21 million.  One of the advantages of this method is the absence of inflation in this model.  And its disadvantages are that due to the limited supply, users start hoarding that token, which in some cases can damage its decentralization feature.</p>
                                                <h3>Inflationary model:</h3>
                                                <p>The behavior of this model is very similar to fiat currencies.  In this model, an unlimited number of tokens are produced in the network using mathematical and economic models.  The difference between this model and fiat currencies is that in this model, the number of tokens that will be minted in the future is determined in advance using coded algorithms, and they can even be removed from the network or so-called <strong>token burning</strong>if needed. But in fiat currencies, banks have the authority to print new money whenever needed.  One of the advantages of this method was less ambiguity for users, and one of the disadvantages is the possibility of high inflation in the network. Ethereum is one of the famous examples which is using this model.</p>
                                                <h3>Duel Token model:</h3>
                                                <p>It can be said that this model is the best and newest economic model for managing the resources and tokens of a blockchain project.  In this method, a token with a limited number is used to create value and store the value of Mint, and another token with an unlimited number is used for services within the blockchain.  The only disadvantage of this method is that it is complicated for investors and users. Vechain is one of the blockchain-based projects which is using this model.</p>
                                                <h3>Asset-Backed model:</h3>
                                                <p>In this method, for each minted token, an asset that is accepted by everyone, such as gold or dollars, is used as the token's support.  This method makes the project token stable and its value remains almost constant over time.  For example, USDT or DAI tokens use this method.  One of the major disadvantages of this method is the lack of transparency and accuracy of the test for the accurate presentation of the supporting assets.</p>
                                                <h3>Conclusion</h3>
                                                <p>In this article, an attempt was made to increase the information of people in this field and give more transparency by examining all the important things that an investor should consider before investing in a blockchain project.</p>
                                            </div>
                                            <ul className="blog-tags">
                                                <li><a href="#">bitcoin</a></li>
                                                <li><a href="#">tokens</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
  )
}

export default Blogs