import React from 'react'

function Hero() {
  return (
    <div className="hero-area mt-5 mb-0 section pb-0">
    <div className="container">
        <div className="row g-3 align-items-center">
            <div className="col-lg-6 order-lg-2">
                <img src="assets/images/hero.gif" alt="#" className="img-fluid" />
            </div>
            <div className="col-lg-6 order-lg-1">
                <div className="inner-content">
                    <div className="hero-text">
                        <h1>Profiltable Leading <br/>of <strong className="text-uppercase text-primary">Cryptocurrency</strong><br/> on the Market</h1>
                        <div className="lead">Get started with the easiest and most Secure palteform to buy,sell,trade and earn cryptocurrencies.</div>
                    </div>
                    <div className='hero-buttons'>
                        <button type="button" className="btn btn-primary mt-4 mb-0 py-2 fs-4 ">Start Trading</button>
                        <div className='midline'>
                            <div/>
                            <div > or continue with </div>
                            <div/>
                        </div>
                        <div className='two-button-wrapper'>
                            <button type="button" className="btn btn-secondary py-2 fs-4 "><img src='assets/images/icons/google.png' alt='logo' style={{ width: '18px', height: '18px' ,marginRight : '6px'}}/>Google</button>
                            <button type="button" className="btn btn-secondary mt-0 py-2 fs-4"><img src='assets/images/icons/apple.svg' alt='logo' style={{ width: '18px', height: '18px' ,marginRight : '6px'}}/>Apple</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row section'>
            <div className='col col-5 col-sm-5 col-md-5 col-xl-3' data-aos="fade-up" data-aos-duration="700">
                <div>$38 billion</div>
                <div>24h trading volume on Binance exchange</div>
            </div>
            <div className='col col-5 col-sm-5 col-md-5 col-xl-3' data-aos="fade-up" data-aos-duration="900">
                <div>350+</div>
                <div>Cryptocurrencies listed</div>
            </div>
            <div className='col col-5 col-sm-5 col-md-5 col-xl-3' data-aos="fade-up" data-aos-duration="1000">
                <div>120 million</div>
                <div>Registered users</div>
            </div>
            <div className='col col-5 col-sm-5 col-md-5 col-xl-3' data-aos="fade-up" data-aos-duration="1200">
                <div>{"<"}0.10%</div>
                <div>Lowest transaction fees</div>
            </div>
        </div> 
    </div>
</div>
  )
}

export default Hero