import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalOpen: false,
    darkMode: '',
    highContrast: '',
    rtlMode: false
  };
  
  const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
      setModalOpen(state, action) {
        state.modalOpen = action.payload;
      },
      setDarkMode(state, action) {
        const val = action.payload;
        if (val === 'dark') {
          window.document.children[0].setAttribute('data-theme', 'dark');
        } else {
          window.document.children[0].setAttribute('data-theme', 'light');
        }
        state.darkMode = val;
        state.highContrast = val;
      },
      setHighContrast(state, action) {
        const val = action.payload;
        if (val === 'high-contrast') {
          window.document.children[0].setAttribute('data-theme', 'high-contrast');
        } else {
          window.document.children[0].setAttribute('data-theme', 'light');
        }
        state.highContrast = val;
        state.darkMode = val;
      },
      setRTLMode(state, action) {
        const val = action.payload;
        if (document.body.classList.contains('rtl_mode')) {
          document.body.classList.remove('rtl_mode');
        } else {
          document.body.classList.add('rtl_mode');
        }
        state.rtlMode = val;
      },
      setMenuData(state, action) {
        state.menuData = action.payload;
      },
    },
  });

  export const {
    setModalOpen,
    setDarkMode,
    setHighContrast,
    setRTLMode,
    setMenuData,
  } = mainSlice.actions;
  
  const mainReducer = mainSlice.reducer;
  
  export default mainReducer;
  