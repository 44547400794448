import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  btcData: {
    marketcap: 0,
    priceChange: 0,
    priceColor: false,
    price: 0,
  },
  ethData: {
    marketcap: 0,
    priceChange: 0,
    priceColor: false,
    price: 0,
  },
  bnbData: {
    marketcap: 0,
    priceChange: 0,
    priceColor: false,
    price: 0,
  },
  lqrData: {
    marketcap: 0,
    priceChange: 0,
    priceColor: false,
    price: 0,
  },
};

const livePriceSlice = createSlice({
  name: 'livePrice',
  initialState,
  reducers: {
    btcPriceChanged(state, action) {
      state.btcData = action.payload;
    },
    ethPriceChanged(state, action) {
      state.ethData = action.payload;
    },
    bnbPriceChanged(state, action) {
      state.bnbData = action.payload;
    },
    lqrPriceChanged(state, action) {
      state.lqrData = action.payload;
    },
  },
});

export const {
  btcPriceChanged,
  ethPriceChanged,
  bnbPriceChanged,
  lqrPriceChanged,
} = livePriceSlice.actions;

export const livePriceReducer = livePriceSlice.reducer;
export default livePriceSlice;

export const loadPrices = (dispatch) => {
  const wsBkex = new WebSocket(
    'wss://api.bkex.com/socket.io/?EIO=3&transport=websocket'
  );
  let pingPong = null;
  wsBkex.onopen = () => {
    pingPong = setInterval(() => {
      wsBkex.send('2');
    }, 20 * 1000);
  };

  wsBkex.onmessage = (event) => {
    const message = event.data;

    if (message === '40') {
      wsBkex.send('40/quotation');
    } else if (message === '40/quotation') {
      wsBkex.send(
        '42/quotation,["subQuotationSymbol",{"symbol": "LQR_USDT"}]'
      );
    } else if (message.startsWith('42/quotation')) {
      const response = JSON.parse(
        message.replace('42/quotation,', '')
      )[1];
      dispatch(lqrPriceChanged(decorateMarketData(response[0])));
    }
  };

  const wsBinance = new WebSocket('wss://stream.binance.com:9443/ws');

  wsBinance.onopen = () => {
    const symbols = ['btcusdt', 'ethusdt', 'bnbusdt'];
    const subscribeMsg = {
      method: 'SUBSCRIBE',
      params: symbols.map((s) => `${s}@ticker`),
      id: 1,
    };
    wsBinance.send(JSON.stringify(subscribeMsg));
  };

  wsBinance.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (data.s) {
      switch (data.s) {
        case 'BTCUSDT': {
          dispatch(btcPriceChanged(decorateMarketData(data)));
          break;
        }
        case 'BNBUSDT': {
          dispatch(bnbPriceChanged(decorateMarketData(data)));
          break;
        }
        case 'ETHUSDT': {
          dispatch(ethPriceChanged(decorateMarketData(data)));
          break;
        }
      }
    }
  };
};

// Selectors
export const btcDataSelector = (state) => state.livePrice.btcData;
export const ethDataSelector = (state) => state.livePrice.ethData;
export const bnbDataSelector = (state) => state.livePrice.bnbData;
export const lqrDataSelector = (state) => state.livePrice.lqrData;

// Utility function
let btcPrevPrice, ethPrevPrice, bnbPrevPrice, lqrPrevPrice = 0;

export const decorateMarketData = (data) => {
  if (data.s) {
    const volume = data.q / 1000000;
    const marketcap = volume > 1000 ? Math.round(volume / 100) / 10 + ' B' : Math.round(volume) + ' M';
    let priceColor = false;
    const price = data.c > 1 ? Math.round(data.c * 100) / 100 : Math.round(data.c * 1000) / 1000;

    switch (data.s) {
      case 'BTCUSDT':
        priceColor = data.c - btcPrevPrice > 0 ? true : false;
        btcPrevPrice = data.c;
        break;
      case 'ETHUSDT':
        priceColor = data.c - ethPrevPrice > 0 ? true : false;
        ethPrevPrice = data.c;
        break;
      case 'BNBUSDT':
        priceColor = data.c - bnbPrevPrice > 0 ? true : false;
        bnbPrevPrice = data.c;
        break;
    }

    const dataN = {
      marketcap: marketcap,
      priceChange: data.P,
      priceColor: priceColor,
      price: price,
    };
    return dataN;
  } else if (data.close) {
    const volume = data.quoteVolume / 1000000;
    const marketcap = volume < 1 ? Math.round(volume * 1000000).toLocaleString() + ' $' : Math.round(volume) + ' M';
    let priceColor = false;
    const price = data.close > 1 ? data.close : Math.round(data.close * 1000) / 1000;

    priceColor = data.close - lqrPrevPrice > 0 ? true : false;
    lqrPrevPrice = data.close;

    const newData = {
      marketcap: marketcap,
      priceChange: data.change,
      priceColor: priceColor,
      price: price,
    };
    return newData;
  }
};

