import React from 'react'

function ContactUs() {
  return (
    <div className="nk-wrap">
        <header className="nk-header" id="privacy-policy">
            <div className="header-banner section">
                <div className="nk-banner">
                    <div className="banner banner-page">
                        <div className="banner-wrap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-9">
                                        <div className="banner-caption cpn tc-light text-center">
                                            <div className="cpn-head">
                                                <h2 className="title ttu">Contact Us</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm shape-a-sm"></div>
            </div>
        </header>
        <main className="nk-pages">
        <section class="section section-contact bg-white">
                            <div class="container">
                                <div class="nk-block block-contact">
                                    <div class="row justify-content-between gutter-vr-30px">
                                        <div class="col-lg-4">
                                            <div class="section-head section-head-sm section-head-s7 wide-auto-sm text-lg-start text-center">
                                                <h2 class="title title-thin">Get In Touch</h2>
                                                <p class="lead"> Any question? Reach out to us and we’ll get back to you shortly </p>
                                            </div>
                                            <div class="pdt-s">
                                                <div class="d-flex flex-column">
                                                    <ul class="contact-list contact-list-s2">
                                                        <li>
                                                            <em class="contact-icon fa fa-phone"></em>
                                                            <div class="contact-text">
                                                                <span>+44 0123 4567</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <em class="contact-icon fa fa-envelope"></em>
                                                            <div class="contact-text">
                                                                <span>info@company.com</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <em class="contact-icon fa fa-paper-plane"></em>
                                                            <div class="contact-text">
                                                                <span>Join us on Telegram</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7">
                                            <div class="bg-light round" style={{borderRadius :'6px'}}>
                                                <form class="contact-form contact-form-s2 boxed boxed-md nk-form-submit" action="form/contact.php" method="post">
                                                    <div class="field-item field-item-s2">
                                                        <input name="contact-name" type="text" class="input-bordered input-bordered-lg input-bordered-light required" placeholder="Your Name"/>
                                                    </div>
                                                    <div class="field-item field-item-s2">
                                                        <input name="contact-email" type="email" class="input-bordered input-bordered-lg  input-bordered-light required email" placeholder="Your Email"/>
                                                    </div>
                                                    <div class="field-item field-item-s2">
                                                        <textarea name="contact-message" class="input-bordered input-bordered-lg input-bordered-light input-textarea required" placeholder="Your Message"></textarea>
                                                    </div>
                                                    <input type="text" class="d-none" name="form-anti-honeypot" value=""/>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <button type="submit" class="btn btn-primary rounded py-2 fs-6">Submit</button>
                                                        </div>
                                                        <div class="col-sm-12">
                                                            <div class="form-results"></div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
        </main>
    </div>
  )
}

export default ContactUs