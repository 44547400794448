import React from 'react'

function Docs({background}) {
  return (
<section className={`section ${background}`} id="docs">
    <div className="container">
        <div className="row mb-3 ">
            <div className="col-xl-7 col-lg-10 col-md-12" data-aos="fade-up" data-aos-duration="700">
                <h2 className="h1">Documents</h2>
                {/* <p className="animated" data-animate="fadeInUp" data-delay="0.2">Download the whitepaper and learn about ICO Token, the unique ICO Crypto approach and the team/advisors.</p> */}
            </div> 
        </div>
    </div>
    <div className="container container-xxl">
        <div className="nk-block">
            <div className="row gutter-vr-50px">
                <div className="col-sm-6 col-lg-3" data-aos="fade-up" data-aos-duration="700" >
                    <div className="doc">
                        <a rel="noopener noreferrer" target="_blank" href="/whitepaper.pdf">
                            <div className="doc-photo doc-shape doc-shape-a">
                                <img src="assets/images/whitepaper/laqira.png" alt="Whitepaper" />
                            </div>
                            <div className="doc-text">
                                <h5 className="doc-title title-sm">Laqira Whitepaper</h5>
                                {/* <a className="doc-download" href="!#"><em className="ti ti-import"></em></a> */}
                                {/* <div className="doc-lang">ENGLISH</div> */}
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3" data-aos="fade-up" data-aos-duration="1000" >
                    <a rel="noopener noreferrer" target="_blank" href="https://laqirace.com/pages/white_paper/v1/Laqirace.pdf">
                        <div className="doc">
                            <div className="doc-photo doc-shape doc-shape-b">
                                <img src="assets/images/whitepaper/laqirace.png" alt="Whitepaper"/>
                            </div>
                            <div className="doc-text">
                                <h5 className="doc-title title-sm">Laqirace Whitepaper</h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-sm-6 col-lg-3" data-aos="fade-up" data-aos-duration="1300" >
                    <a rel="noopener noreferrer" target="_blank" href="https://github.com/slowmist/Knowledge-Base/blob/master/open-report-V2/smart-contract/SlowMist%20Audit%20Report%20-%20LaqiraToken_en-us.pdf">
                        <div className="doc">
                            <div className="doc-photo doc-shape doc-shape-c">
                                <img src="assets/images/whitepaper/slowmist-token.png" alt="Whitepaper" />
                            </div>
                            <div className="doc-text">
                                <h5 className="doc-title title-sm">Token Security Audit <small>Slowmist</small></h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-sm-6 col-lg-3" data-aos="fade-up" data-aos-duration="1600" >
                    <a rel="noopener noreferrer" target="_blank" href="https://github.com/slowmist/Knowledge-Base/blob/master/open-report-V2/smart-contract/SlowMist%20Audit%20Report%20-%20Laqira%20NFT%20marketplace_en-us.pdf">
                        <div className="doc">
                            <div className="doc-photo doc-shape doc-shape-d">
                                <img src="assets/images/whitepaper/slowmist-marketplace.png" alt="Whitepaper" />
                            </div>
                            <div className="doc-text">
                                <h5 className="doc-title title-sm">Marketplace Security Audit <small>Slowmist</small></h5>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Docs