import React from 'react'

function ProductsView({background}) {
  return (
    <div className={`feature section  ${background}`} id="feature">
    <div className="container ">
    <div className="row mb-3">
            <div className="col-xl-7 col-lg-10 col-md-12" data-aos="fade-up" data-aos-duration="700" >
                <h2 className="h1">Explore Our Full Product Suite Here</h2> 
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 col-12" data-aos="fade-up" data-aos-duration="700">
                <div className="feature-box page-three products-view">
                    <div>
                        <h4 className="text-title">High-level security</h4>
                        <p>Top digital asset players trust our custody service for eliminating human errors.</p>
                        <button>➔</button>
                    </div>
                    <div className="tumb">
                        <img src="assets/images/features/feature-icon-1.png" alt="" />
                    </div>

                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12" data-aos="fade-up" data-aos-duration="1000">
                <div className="feature-box page-three products-view">
                    <div>
                        <h4 className="text-title">Proprietary coding</h4>
                        <p>We offer tech solutions for safekeeping and trading of blockchain-based assets, with easy client access.</p>
                        <button>➔</button>
                    </div>
                    <div className="tumb">
                        <img src="assets/images/features/feature-icon-2.png" alt="" />
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12" data-aos="fade-up" data-aos-duration="1300">
                <div className="feature-box page-three products-view">
                    <div>
                        <h4 className="text-title">Modular Architecture</h4>
                        <p>Scale the platfrom to gradually accommodate growing trading volume and system load.</p>
                        <button>➔</button>
                    </div>
                    <div className="tumb">
                        <img src="assets/images/features/feature-icon-1.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default ProductsView