import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'

function Navbar() {

    const [dropdownState, setDropdownState] = useState('');
    const [mobileToggle, setMobileToggle] = useState(false);
    
  return (
    <nav className="navbar navbar-expand-xl py-lg-3 lp-menu">
    <div className="container py-2 rounded">
        <a className="navbar-brand fw-bold  fs-3" href="/" title="Logo"><img src="/assets/images/logo.png" alt="logo"/></a>
        <button className={ mobileToggle === false ? "navbar-toggler mobile-menu-btn collapsed" : "navbar-toggler mobile-menu-btn" } 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarSupportedContent" 
            aria-expanded={mobileToggle}
            style={{minWidth: '43px'}}
            onClick={e=> {
                setTimeout(() => {
                    setMobileToggle(!mobileToggle)
                  }, 300)
            }}
            >
            <FontAwesomeIcon className={mobileToggle ? "transition-icon animate-icon" : "transition-icon"} icon={mobileToggle ? faXmark : faBars} />
        </button>
        <div className={ mobileToggle === false ? "collapse navbar-collapse" : "collapse navbar-collapse show" } id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {/* <li className="nav-item" onClick={e=> { setMobileToggle(!mobileToggle) }} ><a className="nav-link me-2" href="/">Trade</a></li> */}

                <li className='nav-item'>
                    <div className="dropdown" onMouseLeave= { e=> { e.preventDefault() ;setDropdownState('') }} >
                        <a className={`nav-link dropdown-toggle ${dropdownState === 1 ? "show" : ''}`}  href="#" role="button"  data-bs-toggle="dropdown" aria-expanded={dropdownState === 1 ? "false" : "true" } onMouseEnter= { e=> { e.preventDefault() ;setDropdownState(1) }}>Trade</a>
                        <div className={`dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0 ${dropdownState === 1 ? "show" : ''}`}data-bs-popper={dropdownState === 1 ? "" : "static" }>
                            <div className="card border-0">
                                <ul className="list-unstyled py-2 px-1">
                                    <li><a href="/btc-to-usdt"> Spot Trading</a></li>
                                    <li><a href="/futures/btc-to-usdt"> Futures Trading</a></li>
                                    <li><a href="/"> Copy Trading</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className='nav-item'>         
                    <div className="dropdown" onMouseLeave= { e=> { e.preventDefault() ;setDropdownState('') }} >
                        <a className={`nav-link dropdown-toggle ${dropdownState === 2 ? "show" : ''}`}  href="#" role="button"  data-bs-toggle="dropdown" aria-expanded={dropdownState === 2 ? "false" : "true" } onMouseEnter= { e=> { e.preventDefault() ;setDropdownState(2) }}>Account</a>
                        <div className={`dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0 ${dropdownState === 2 ? "show" : ''}`}data-bs-popper={dropdownState === 2 ? "" : "static" }>
                            <div className="card border-0">
                                <ul className="list-unstyled py-2 px-1">
                                    <li><a href="/signinapp">Login</a></li>
                                    <li><a href="/signupapp">Register</a></li>
                                    <li><a href="/settings">Settings</a></li>
                                    <li><a href="/account">Account Management</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className='nav-item'>
                    <div className="dropdown" onMouseLeave= { e=> { e.preventDefault() ;setDropdownState('') }} >
                        <a className={`nav-link dropdown-toggle ${dropdownState === 3 ? "show" : ''}`}  href="#" role="button"  data-bs-toggle="dropdown" aria-expanded={dropdownState === 3 ? "false" : "true" } onMouseEnter= { e=> { e.preventDefault() ;setDropdownState(3) }}>Product</a>
                        <div className={`dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0 ${dropdownState === 3 ? "show" : ''}`}data-bs-popper={dropdownState === 3 ? "" : "static" }>
                            <div className="card border-0">
                                <ul className="list-unstyled py-2 px-1">
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://laqira.io/">Laqira Protocol</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://dex.laqira.com">Laqira DEX</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://marketplace.laqira.io/">NFT marketplace</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://laqirace.com/">Laqirace Game<span className='badge badge-xs badge-light'>ON BSC</span></a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://blog.laqira.io/">Blog</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className='nav-item'>
                    <div className="dropdown" onMouseLeave= { e=> { e.preventDefault() ;setDropdownState('') }} >
                        <a className={`nav-link dropdown-toggle ${dropdownState === 4 ? "show" : ''}`}  href="#" role="button"  data-bs-toggle="dropdown" aria-expanded={dropdownState === 4 ? "false" : "true" } onMouseEnter= { e=> { e.preventDefault() ;setDropdownState(4) }}>Audit</a>
                        <div className={`dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0 ${dropdownState === 4 ? "show" : ''}`}data-bs-popper={dropdownState === 4 ? "" : "static" }>
                            <div className="card border-0">
                                <ul className="list-unstyled py-2 px-1">
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/slowmist/Knowledge-Base/blob/master/open-report-V2/smart-contract/SlowMist%20Audit%20Report%20-%20LaqiraToken_en-us.pdf">LQR
                                    Token Security Audit</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/slowmist/Knowledge-Base/blob/master/open-report-V2/smart-contract/SlowMist%20Audit%20Report%20-%20Laqira%20NFT%20marketplace_en-us.pdf">Marketplace
                                    Security Audit</a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li className='nav-item'>
                    <div className="dropdown" onMouseLeave= { e=> { e.preventDefault() ;setDropdownState('') }} >
                        <a className={`nav-link dropdown-toggle ${dropdownState === 5 ? "show" : ''}`}  href="#" role="button"  data-bs-toggle="dropdown" aria-expanded={dropdownState === 5 ? "false" : "true" } onMouseEnter= { e=> { e.preventDefault() ;setDropdownState(5) }}>NFT</a>
                        <div className={`dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0 ${dropdownState === 5 ? "show" : ''}`}data-bs-popper={dropdownState === 5 ? "" : "static" }>
                            <div className="card border-0">
                                <ul className="list-unstyled py-2 px-1">
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://marketplace.laqira.io/">Laqira NFT marketplace</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://marketplace.laqira.io/explore">Explore</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://marketplace.laqira.io/mint_nft">Mint your NFT</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="nav-item" ><a className="nav-link me-2" href="/market-overview">Market Overview</a></li>
                
                <li className='nav-item'>
                    <div className="dropdown" onMouseLeave= { e=> { e.preventDefault() ;setDropdownState('') }} >
                        <a className={`nav-link dropdown-toggle ${dropdownState === 6 ? "show" : ''}`}  href="#" role="button"  data-bs-toggle="dropdown" aria-expanded={dropdownState === 6 ? "false" : "true" } onMouseEnter= { e=> { e.preventDefault() ;setDropdownState(6) }}>About</a>
                        <div className={`dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0 ${dropdownState === 6 ? "show" : ''}`}data-bs-popper={dropdownState === 6 ? "" : "static" }>
                            <div className="card border-0">
                                <ul className="list-unstyled py-2 px-1">
                                    <li><a href="/about-us">About</a></li>
                                    <li><a href="/announcements">Announcements</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href="/end-user-license-agreement-eula">EULA</a></li>
                                    <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                                    <li><a href="/changelog">Changelog</a></li>
                                    <li><a href="/contact-us">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            
            </ul>
            <form className="d-flex">
                        <div className="dropdown Language d-none d-sm-block">
                            <div className="dropdown">
                                <a className="nav-link dropdown-toggle pulse" href="#" role="button" data-bs-toggle="dropdown" style={{pointerEvents: "none"}}>
                                    <img src="assets/images/flag/GB.png" alt="" />
                                </a>
                                <div className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0">
                                    <div className="card border-0">
                                        <ul className="list-unstyled py-2 px-3">
                                            <li>
                                                <a href="#" className=""><img src="assets/images/flag/GB.png" alt="" /> English</a>
                                            </li>
                                            {/* <li>
                                                <a href="#" className=""><img src="assets/images/flag/DE.png" alt="" /> German</a>
                                            </li>
                                            <li>
                                                <a href="#" className=""><img src="assets/images/flag/FR.png" alt="" /> French</a>
                                            </li>
                                            <li>
                                                <a href="#" className=""><img src="assets/images/flag/IT.png" alt="" /> Italian</a>
                                            </li>
                                            <li>
                                                <a href="#" className=""><img src="assets/images/flag/RU.png" alt="" /> Russian</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown Language d-none d-sm-block">
                            <div className="dropdown" onMouseLeave= { e=> { e.preventDefault() ;setDropdownState('') }}>
                                <a className={`nav-link dropdown-toggle ${dropdownState === 7 ? "show" : ''}`} href="#" role="button" data-bs-toggle="dropdown" aria-expanded={dropdownState === 7 ? "false" : "true" } onMouseEnter= { e=> { e.preventDefault();setDropdownState(7) }}> Download </a>
                                <div className={`dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0 ${dropdownState === 7 ? "show" : ''}`}data-bs-popper={dropdownState === 7 ? "" : "static" }>
                                    <div className="card border-0">
                                        <ul className="list-unstyled py-2 px-1">
                                            <li>
                                                <img src="assets/images/qr-code.png" alt="" className="img-fluid" />
                                                <span className="small text-muted px-2 mt-1">Download Mobile App</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="/sign-in" title="login" className="btn mx-sm-1 py-2">Login</a>
                        <a href="/sign-up" title="Register" className="btn btn-primary rounded py-2 fs-6">Register</a>
                    </form>
        </div>
    </div>
</nav>
  )
}

export default Navbar