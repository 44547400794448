import React, { useState } from 'react';
import { connect } from 'react-redux';
import Sidebar from './Component/Comman/Sidebar';
import MainIndex from './Screen/MainIndex';
import {  useNavigate } from 'react-router-dom';
import AuthIndex from './Screen/AuthIndex';
import menu from '../src/Component/Data/Menu/Menu.json';
import menu2 from '../src/Component/Data/Menu/Menu2.json';
import { includes } from 'lodash';
import Router from './Router';

function App(props) {
  const [menuData, setMenuData] = useState([...menu.menu]);
  const navigate = useNavigate();
  var baseUrl = process.env.PUBLIC_URL
  const activekey = () => {
    var res = window.location.pathname
    var baseUrl = process.env.PUBLIC_URL
    baseUrl = baseUrl.split("/");
    // res = res.split("/");
    // res = res.length > 0 ? res[baseUrl.length] : "/";
    // res = res ? "/" + res : "/";
    const activeKey1 = res;
    return activeKey1
  }

  const GotoChangeMenu = (val) => {
    if (val === "UI Components") {
      navigate(baseUrl + "/dashboard/ui-alerts")
      setMenuData([...menu2.menu2]);
      // console.log([...menu2.menu2])
    } else if (val === "changelog") {
      navigate(baseUrl + "/dashboard/ui-alerts")
      setMenuData([...menu2.menu2]);
    } else if (val === "documentation") {
      navigate(baseUrl + "/dashboard/ui-alerts")
      setMenuData([...menu2.menu2]);
    } else {
      // props.history.push("dashboard");
      navigate(baseUrl + '/dashboard/')
      setMenuData([...menu.menu]);
    }
  }

  if (activekey() === '/sign-in' || activekey() === '/sign-up' || activekey() === '/forgot-password' || activekey() === '/verification' || activekey() === '/404page') {
    return (
      <div id="laqira-layout" className='theme-orange'>
        {/* <AuthIndex /> */}
      </div>
    );
  } 
  if(includes(activekey(),'dashboard')){
    // return (
    //   <div id='laqira-layout' className='theme-orange'>
    //     <Sidebar
    //       activekey={activekey()}
    //       menuData={menuData}
    //       GotoChangeMenu={(val) => { GotoChangeMenu(val) }}
    //     />
    //     <MainIndex activekey={activekey()}
    //       GotoChangeMenu={(val) => { GotoChangeMenu(val) }}
    //     />
    //   </div>
    // );
  } else {
    return (
      <Router />
    )
  }
}
const mapStateToProps = ({ Mainreducer }) => ({
  Mainreducer
})

export default connect(mapStateToProps, {

})(App);
