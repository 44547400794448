import React from 'react'

function Video({background}) {
  return (
<section className={`intro-video-area section ${background}`}>
    <div className="container">
        <div className="row justify-content-center justify-content-center align-items-center" >
            <div className="col-lg-7 justify-content-center">
                <div className="section-title p-0 mb-0 text-center" data-aos="fade-up" data-aos-duration="700">
                    <h3>Create your own experience</h3>
                    <h2>Watch Our Intro Video </h2>
                    <p>Get started with the easiest and most Secure palteform to buy,sell,trade and earn cryptocurrencies.</p>
                </div>
                <div className="inner-content-head" data-aos="fade-up" data-aos-duration="1000">
                    <div className="inner-content">
                        <img className="shape1" src="assets/images/shape1.svg" alt="#" />
                        <img className="shape2" src="assets/images/shape2.svg" alt="#" />
                        <div className="intro-video-play">
                            <div className="play-thumb">
                                <a href="https://www.youtube.com/watch?v=EZyAJ79PTZM"
                                    className="glightbox video"><i className="fa fa-play"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Video