import React, { Fragment, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { showText } from '../../../helper'

const data = [
    {
        date : "Jan 14, 2023 14:30 UTC",
        title : 'Ability to transfer NFTs to others 1',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "/assets/images/news/img1.jpg",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "Jan 14, 2023 14:30 UTC",
        title : 'Ability to transfer NFTs to others 2',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "Jan 14, 2023 14:30 UTC",
        title : 'Ability to transfer NFTs to others 3',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "/assets/images/news/img3.jpg",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "5/22/2023",
        title : 'Ability to transfer NFTs to others 4',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "5/22/2023",
        title : 'Ability to transfer NFTs to others 5',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "Jan 14, 2023 14:30 UTC",
        title : 'Ability to transfer NFTs to others 6',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "/assets/images/news/img1.jpg",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "Jan 14, 2023 14:30 UTC",
        title : 'Ability to transfer NFTs to others',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "Jan 14, 2023 14:30 UTC",
        title : 'Ability to transfer NFTs to others',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "/assets/images/news/img3.jpg",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "5/22/2023",
        title : 'Ability to transfer NFTs to others',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "5/22/2023",
        title : 'Ability to transfer NFTs to others',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "Jan 14, 2023 14:30 UTC",
        title : 'Ability to transfer NFTs to others',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "/assets/images/news/img1.jpg",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "Jan 14, 2023 14:30 UTC",
        title : 'Ability to transfer NFTs to others',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "Jan 14, 2023 14:30 UTC",
        title : 'Ability to transfer NFTs to others',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "/assets/images/news/img3.jpg",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "5/22/2023",
        title : 'Ability to transfer NFTs to others',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
    {
        date : "5/22/2023",
        title : 'Ability to transfer NFTs to others',
        caption: 'Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible  ....',
        img : "",
        text: "Non-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.\nNon-fungible tokens (NFTs) are registered as digital assets on the blockchain network. One advantage of the blockchain network is that it is also possible to register the ownership of non-fungible tokens. On Laqira Protocol NFT marketplace, users are enabled to transfer the ownership of their NFTs on BSC to others; But the creator will remain the same person.",
    },
]
function Announcement() {

    const [modalActive ,setModalActive ] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const handleModal = (index) => {
        setModalActive(index)
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    
  return (
    <div className="nk-wrap">
        <header className="nk-header" id="privacy-policy">
            <div className="header-banner section">
                <div className="nk-banner">
                    <div className="banner banner-page">
                        <div className="banner-wrap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-9">
                                        <div className="banner-caption cpn tc-light text-center">
                                            <div className="cpn-head">
                                                <h2 className="title ttu">Announcements</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm shape-a-sm"></div>
            </div>
        </header>
        <main className="nk-pages">
            <section className="section bg-white">
                <div className="container">
                    <div className="">
                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-8 col-lg-10">
                                            <div className='announcement'>
                                                <div className="row">
                                                {currentItems.map((item,index) => {
                                                    return(
                                                    <Fragment key={index}>
                                                        <div className="col-12 col-md-12 mt-3">
                                                            <div className="card" onClick={() => { handleModal(index) }}>
                                                                <div className="card-body">
                                                                    <div className="pt-2">
                                                                        <h6 className="d-inline-block"><span className="">{item.title}</span></h6>
                                                                        <span className="text-muted">&nbsp;&nbsp;&nbsp;- {item.date}</span>
                                                                        <p style={{marginLeft: '5px'}}>{item.caption}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Modal show={modalActive === index } centered onHide={() => { setModalActive(false) }} className='modal-lg'>
                                                            <Modal.Header>
                                                                <h5 className="modal-title" id="staticBackdropLiveLabel">{item.title}</h5>
                                                                <button type="button" className="btn-close" onClick={() => { setModalActive(false) }}  ></button>
                                                            </Modal.Header>

                                                            <Modal.Body>
                                                                {item.img ? 
                                                                    <div className='row'>
                                                                        <div className='col-lg-6'style={{margin: 'auto'}}>
                                                                            <img src={item.img} alt="annoucment" style={{maxWidth: '100%'}}/> 
                                                                        </div>
                                                                        <div className='col-lg-6'>
                                                                            <p>{showText(item.text)}</p>
                                                                        </div>
                                                                    </div>
                                                                    : <p>{showText(item.text)}</p>
                                                                }
                                                            </Modal.Body>
                                                        </Modal>
                                                    </Fragment>
                                                    )
                                                })}
                                                </div>
                                                {/* Pagination */}
                                                <div className="pagination">
                                                    <ul>
                                                        {currentItems.map((_, index) => {
                                                            const pageNumber = index + 1;
                                                            return (
                                                                <li key={index} className={pageNumber === currentPage ? 'active' : ''}>
                                                                    <button onClick={() => paginate(pageNumber)}>{pageNumber}</button>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
  )
}

export default Announcement;


