import React, { Fragment } from 'react'

const data = [
    {
        version : "[Unreleased]",
        date : "yyyy-mm-dd",
        data : [
            {
                title: 'Added',
                captions : [
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    },
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    }
                ]
            },
            {
                title: 'Changed',
                captions : [
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    },
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    }
                ]
            },
            {
                title: 'Fixed',
                captions : [
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    },
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    }
                ]
            },
        ]
    },
    {
        version : "[Unreleased]",
        date : "yyyy-mm-dd",
        data : [
            {
                title: 'Added',
                captions : [
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    },
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    }
                ]
            },
            {
                title: 'Changed',
                captions : [
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    },
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    }
                ]
            },
            {
                title: 'Fixed',
                captions : [
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    },
                    {
                        text : 'PROJECTNAME-XXXX MINOR Ticket title goes here.'
                    }
                ]
            },
        ]
    },
]

function ChangeLogPage() {
  return (
    <div className="nk-wrap">
        <header className="nk-header" id="privacy-policy">
            <div className="header-banner section">
                <div className="nk-banner">
                    <div className="banner banner-page">
                        <div className="banner-wrap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-9">
                                        <div className="banner-caption cpn tc-light text-center">
                                            <div className="cpn-head">
                                                <h2 className="title ttu">Changelog</h2>
                                                <p>All notable changes to this project will be documented in this file.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm shape-a-sm"></div>
            </div>
        </header>
        <main className="nk-pages">
            <section className="section bg-white">
                <div className="container">
                    <div className="">
                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-8 col-lg-10">
                                            <div className='changelog'>
                                                <div className="row">
                                                    {data.map((item,index1) => {
                                                        const {title,version,date,data} = item
                                                        return(
                                                        <div className="col-12 col-md-12 mt-3" key={index1}>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="pt-2">
                                                                        <h6 className="d-inline-block">{version}</h6>{/*<span className="badge bg-warning font-weight-light">v1.0.0</span> */}
                                                                        <span className="text-muted">&nbsp;&nbsp;&nbsp;- {date}</span>
                                                                        {data.map((obj,index2) =>{
                                                                            return(
                                                                            <Fragment key={index2}>
                                                                                <h4>{obj.title}</h4>
                                                                                <ul className="ms-5">

                                                                                {obj.captions.map((captions,i) => {
                                                                                    return(
                                                                                    <li key={i}>{captions.text}</li>
                                                                                    )
                                                                                })}
                                                                                </ul>
                                                                            </Fragment>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
  )
}

export default ChangeLogPage