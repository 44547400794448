import React ,{ Fragment } from 'react'
import { split } from 'lodash'

const data = [
{
    icon:'icon ikon ',
    img: 'icon-t12.png',
    title: 'Expert team behind the scenes',
    caption:'Laqira protocol has a team of the most highly skilled engineers and developers in the industry, with extensive expertise in various technologies including blockchain, server, security, backend, frontend and more.'
},
{
    icon:'icon ikon ',
    img: 'icon-t13.png',
    title: 'Ecosystem Expansion Policy',
    caption:'According to Blockchain’s main prophecy, Laqira Protocol aim is to expand a fully decentralized environment in which users are able to participate anonymously as much as possible.'
},
{
    icon:'icon ikon ',
    img: 'icon-t12.png',
    title: 'Precious Mission on the Horizon',
    caption:'Laqira Protocol aims to develop innovative products and applications toward online entrepreneurship which is a laudable scope.'
}
]

const showData = data => {
    return(
        data.map((data,index) => {
            return(
            <Fragment key={index}>
                <div className="feature feature-s2">
                    <div className="feature-icon dot">
                        {/* <em className={data.icon}><img src={"assets/images/icons/"+ data.img} alt="Why Laqira"></img></em> */}
                    </div>
                    <div className="feature-text">
                        <h5 className="text-title text-primary h4">{showText(data.title)}</h5>
                        <p className='lead'>{showText(data.caption)}</p>
                    </div>
                </div>     
            </Fragment>
            )
        })
    )
}

const showText = data => {
    return(
        split(data ,"\n").map((text ,index) => {
            return(
                <Fragment key={index}>
                    <>{text}</>
                    <br  className="d-none d-md-block"/>
                </Fragment>
            )
        })
    )
}

function Features({background}) {
  return (
<section className={`section section-features ${background}`} id="features">
    <div className="container">
        <div className="row mb-5">
            <div className="col-xl-7 col-lg-10 col-md-12 title-wrapper" data-aos="fade-up" data-aos-duration="700">
                <h2 className="h1">Why Laqira protocol ?</h2>
                {/* <a href="!#">
                    View more markets
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className=""><path fillRule="evenodd" clipRule="evenodd" d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z" fill="currentColor"></path></svg>
                </a> */}
            </div>
        </div>
        <div className="nk-block nk-block-features-s2">
            <div className="row align-items-center flex-row-reverse">
                <div className="col-lg-5">
                    <div className="gfx py-4" data-aos="fade-up" data-aos-duration="700">
                        <img src="assets/images/why-laqira.png" alt="Why Laqira" style={{maxWidth :'100%'}} />
                    </div>
                </div>
                <div className="col-lg-7" data-aos="fade-up" data-aos-duration="1000">
                    <div className="features-list me-4 mgb-m30">
                        {showData(data)}
                    </div>
                    <button type="button" className="btn btn-primary my-4 py-2 fs-5 ">Start Trading</button>

                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Features