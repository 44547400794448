import React from 'react'

function NeedHelp({background}) {
  return (
<div className={`section get-touch ${background}`} id="get-touch">
    <div className="container">
        <div className="row mb-5">
            <div className="col-xl-7 col-lg-10 col-md-12" data-aos="fade-up" data-aos-duration="700">
                <h2 className="h1">Get in touch. <span className="text-primary">Stay in touch.</span></h2> 
                <p className="lead">If you really care about someone you will make time for them.</p>
            </div>
        </div>
        <div className="row g-3 align-items-center pt-2">
            <div className="col-xl-3" data-aos="fade-up" data-aos-duration="700">
                <div className="card p-3">
                    <i className="icofont-live-support fs-1 chart-text-color5"></i>
                    <h5 className="my-3">24 / 7 Support</h5>
                    <p>Got a problem? Just get in touch. Our support team is available 24/7.</p>
                </div>
            </div>
            <div className="col-xl-3" data-aos="fade-up" data-aos-duration="1000">
                <div className="card p-3">
                    <i className="icofont-file-document fs-1 chart-text-color5"></i>
                    <h5 className="my-3">Cryptoon Blog</h5>
                    <p>News and updates from the world’s leading cryptocurrency exchange.</p>
                </div>
            </div>
            <div className="col-xl-3" data-aos="fade-up" data-aos-duration="1300">
                <div className="card p-3">
                    <i className="icofont-speech-comments fs-1 chart-text-color5"></i>
                    <h5 className="my-3">Community</h5>
                    <p>Cryptoon is global. Join the discussion in our worldwide communities.</p>
                </div>
            </div>
            <div className="col-xl-3" data-aos="fade-up" data-aos-duration="1600">
                <div className="card p-3">
                    <i className="icofont-hat fs-1 chart-text-color5"></i>
                    <h5 className="my-3">Careers</h5>
                    <p>Help build the future of technology. Start your new career at Cryptoon.</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default NeedHelp