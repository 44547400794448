import React from 'react'

function Footer() {
  return (
<div className="section footer color-bg-100 pb-0">
    <div className="container">
        <div className="row mb-5">
            <div className="col-lg-4 col-md-6 col-12">
                <div className="d-flex align-items-center">
                    {/* <h4 className="mb-4 fw-bold "><i className="fa fa-gg-circle fs-3 text-primary pe-2"></i>Cryptoon</h4> */}
                    <img src='assets/images/logo.png' alt='LaqiraProtocol'style={{paddingBottom: '5px'}}/>
                </div>
                {/* <ul className="social">
                    <li><a target="_blank" rel="noopener noreferrer"
                            href="https://t.me/laqira_official"><em
                                className="social-icon fab fa-telegram"></em></a></li>
                    <li><a target="_blank" rel="noopener noreferrer"
                            href="https://discord.gg/ZGW3Ga7AKh"><em
                                className="social-icon fab fa-discord"></em></a></li>
                    <li><a target="_blank" rel="noopener noreferrer"
                            href="https://www.reddit.com/user/laqiraprotocol/"><em
                                className="social-icon fab fa-reddit"></em></a></li>
                    <li><a target="_blank" rel="noopener noreferrer"
                            href="https://twitter.com/LaqiraProtocol"><em
                                className="social-icon fab fa-twitter"></em></a></li>
                    <li><a target="_blank" rel="noopener noreferrer"
                            href="https://www.youtube.com/channel/UCf_AmXXqUvFsnoWCv2liSUg"><em
                                className="social-icon fab fa-youtube"></em></a></li>
                    <li><a target="_blank" rel="noopener noreferrer"
                            href="https://github.com/LaqiraProtocol"><em
                                className="social-icon fab fa-github"></em></a></li>
                    <li><a target="_blank" rel="noopener noreferrer"
                            href="https://www.linkedin.com/in/laqira-protocol-295a6b222/"><em
                                className="social-icon fab fa-linkedin"></em></a></li>
                    <li><a target="_blank" rel="noopener noreferrer"
                            href="https://medium.com/@laqiraprotocol"><em
                                className="social-icon fab fa-medium-m"></em></a></li>
                </ul> */}
            <span className="lead">An integrated metaverse complex offering beneficial services and technologies including Defi, Gamefi, wallet, exclusive dapps and etc, with the most user-friendly for the users.</span>

            <ul className="mt-3 fs-6 list-unstyled">
                <li><span>Address:</span> 2071 Eva Pearl Street,LA</li>
                <li><span>Email:</span> example@pw.com</li>
                <li><span>Call:</span> 225-747-3640</li>
            </ul>
            </div>
            <div className="col-lg-8 col-md-6 col-12">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-4">
                        <h5 className="mb-4">Product</h5>
                        <ul className="footer-link list-unstyled">
                            <li className="my-1"><a target="_blank" rel="noopener noreferrer" href="https://laqira.io/">Laqira Protocol</a></li>
                            <li className="my-1"><a target="_blank" rel="noopener noreferrer" href="https://dex.laqira.com">Laqira DEX</a></li>
                            <li className="my-1"><a target="_blank" rel="noopener noreferrer" href="https://marketplace.laqira.io/">NFT Marketplace</a></li>
                            <li className="my-1"><a target="_blank" rel="noopener noreferrer" href="https://laqirace.com/">Laqirace Game</a></li>
                            <li className="my-1"><a target="_blank" rel="noopener noreferrer" href="https://blog.laqira.io/">Laqira Blog</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-4">
                        <h5 className="mb-4">Company</h5>
                        <ul className="footer-link list-unstyled">
                            <li className="my-1"><a href="/contact-info">Contact Us</a></li>
                            <li className="my-1"><a target="_blank" rel="noopener noreferrer" href="https://support.laqira.com/">Support</a></li>
                            <li className="my-1"><a href="/legal-information">Legal</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-4">
                        <h5 className="mb-4">Utility</h5>
                        <ul className="footer-link list-unstyled">
                            <li className="my-1"><a target="_blank" rel="noopener noreferrer" href="https://api.laqira.com/">API Docs</a></li>
                            <li className="my-1"><a href="/widget">Widget</a></li>
                            <li className="my-1"><a href="/system-monitor">System Monitor</a></li>
                            <li><a href="/sub-accounts">Sub Accounts</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-4">
                        <h5 className="mb-4">Account</h5>
                        <ul className="footer-link list-unstyled">
                            <li className="my-1"><a href="/signinapp">Login</a></li>
                            <li className="my-1"><a href="/signupapp">Register</a></li>
                            <li className="my-1"><a href="/setting">Setting</a></li>
                            <li><a href="/accounts">Account Managment</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.laqira.exchange&hl=en&gl=US">Download (Android)</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="footer-bottom pt-4">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                    <p>© 2023 Laqira Exchange. Made by Laqira Protocol | V{process.env.REACT_APP_VERSION}</p>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <ul className="list-unstyled d-flex justify-content-md-end">
                        <li><a className="p-2 ms-2" href="#">Terms of use</a></li>
                        <li><a className="p-2 ms-2" href="#">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Footer