import { useState, useEffect, useRef } from 'react'

const Partners = ({background}) => {
  const [partners, setPartners] = useState([
    {
      img: '/assets/images/featured-in/benzinga.png',
      link: 'https://www.benzinga.com/pressreleases/22/01/25129870/laqirace-the-worlds-first-drive2earn-blockchain-game',
    },
    {
      img: '/assets/images/featured-in/digital journal.png',
      link: 'https://www.digitaljournal.com/pr/laqirace-the-worlds-first-drive2earn-blockchain-game',
    },
    {
      img: '/assets/images/featured-in/yahoo finance.png',
      link: 'https://finance.yahoo.com/news/laqirace-worlds-first-drive2earn-blockchain-120700129.html?guccounter=1',
    },
    {
      img: '/assets/images/featured-in/yahoo news.png',
      link: '',
    },
    {
      img: '/assets/images/featured-in/nasdaq.png',
      link: 'https://www.nasdaq.com/press-release/laqirace%3A-the-worlds-first-drive2earn-blockchain-game-2022-01-20',
    },
    {
      img: '/assets/images/featured-in/bloomberg.png',
      link: 'https://www.bloomberg.com/press-releases/2022-01-20/laqirace-the-world-s-first-drive2earn-blockchain-game',
    }
  ])

  const [currentIndex, setCurrentIndex] = useState(0)
  const timerRef = useRef()

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setCurrentIndex(currentIndex => (currentIndex + 1) % partners.length)
    }, 3000)
    return () => clearInterval(timerRef.current)
  }, [partners.length])

  return (
    <div className={`partners-carousel-container container`}>
            {/* <div className="col-12">
                <div className="section-title">
                    <h2 className="h1 " >Our Partners</h2>
                </div>
            </div> */}
      <div
        className="partners-carousel"
        style={{
          transform: `translateX(${-currentIndex * 20}%)`,
        }}
      >
        {partners.map((partner, index) => (
          <div className="partner-logo" key={index}>
            <a href={partner.link} target="_blank" rel="noopener noreferrer" >
              <img className="logo-icon" src={partner.img} alt="logo"/>
            </a>
          </div>
        ))}
        {partners.map((partner, index) => (
          <div className="partner-logo" key={`copy-${index}`}>
            <a href={partner.link} target="_blank" rel="noopener noreferrer" >
              <img className="logo-icon" src={partner.img} alt="logo"/>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Partners
