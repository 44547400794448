import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from 'react-router-dom';


const data = [
    {
        img: '/assets/images/news/img1.jpg',
        title: 'Bitcoin, Ethereum fall, Dogecoin surges over 4%',
        caption: '',
        day: '16',
        month: 'August',
        year: '2021',
        id: '1'

    },
    {
        img: '/assets/images/news/img2.jpg',
        title: 'Mining power plant raises ire of environmentalists',
        caption: '',
        day: '15',
        month: 'May',
        year: '2020',
        id: '2'

    },
    {
        img: '/assets/images/news/img3.jpg',
        title: 'Invest in the world largest equity market',
        caption: '',
        day: '20',
        month: 'March',
        year: '2023',
        id: '3'

    },
    {
        img: '/assets/images/news/img4.jpg',
        title: 'Bitcoin, Ethereum fall, Dogecoin surges over 4%',
        caption: '',
        day: '30',
        month: 'August',
        year: '2022',
        id: '4'

    },
    {
        img: '/assets/images/news/img5.jpg',
        title: 'Mining power plant raises ire of environmentalists',
        caption: '',
        day: '14',
        month: 'August',
        year: '2022',
        id: '5'

    },
    {
        img: '/assets/images/news/img6.jpg',
        title: 'Fuck Invest in the world largest equity market',
        caption: '',
        day: '10',
        month: 'March',
        year: '2023',
        id: '6'

    },
]
  
  function Blog({ background }) {
    var settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 1100,
        slidesToShow: 3,
        slidesToScroll: 2,
        responsive: [
            {breakpoint: 1, settings: { slidesToShow: 1 ,slidesToScroll: 1,}},
            {breakpoint: 550, settings: { slidesToShow: 1 ,slidesToScroll: 1, }},
            {breakpoint: 920, settings: { slidesToShow: 2 }}],
      };
  
    return (
      <div className={`section blogs ${background}`} id="Blogs">
        <div className="container" >
            <div className="col-lg-12 col-xl-12 order-xl-2">
                <div className="row mb-5 justify-content-end" data-aos="fade-up" data-aos-duration="700">
                    <div className="col-xl-12 col-lg-12 col-md-12 text-start">
                        <span className="text-primary fw-bold fs-6 letter-spacing text-uppercase">Latest Blogs</span>
                        <h2 className="h1">Top cryptocurrency news</h2> 
                        <p className="lead">Most interesting articles on cryptocurrencies</p>
                    </div>
                </div>
                <div className="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-deck">
                    <Slider {...settings}  style={{width: '94%' ,margin: 'auto'}}>
                    {data.map((item, index) => (
                        <div  className="col" key={index} data-aos="fade-up" data-aos-duration="1000">
                        <Link to={`/blogs/${item.id}`}>
                        <div className="card border-2 position-relative">
                            <img src={item.img} className="img-fluid card-img-top" alt="..." />
                            <div className="d-flex align-items-center top-date chart-color1">
                                <div className="one mx-2">
                                <span>{item.day}</span>
                                </div>
                                <div className="two">
                                <span>{item.year}</span>
                                <span>{item.month}</span>
                                </div>
                            </div>
                            <div className="card-body">
                                <h5>{item.title}</h5>
                                <p>{item.caption}</p>
                            </div>
                            </div>
                        </Link>
                        </div>
                    ))}
                    </Slider>
                </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Blog;
  
