import React, { useState, useRef } from 'react'

function GetStart() {

    const [activeIndex, setActiveIndex] = useState(1);
    const divRefs = useRef([]);
  
    function handleMouseEnter(index) {
      setActiveIndex(index);
    }
  
  return (
<div className="intro-video-area section bg-white pt-5" id="">
    <div className="container">
        <div className="row mb-1">
            <div className="col-xl-7 col-lg-10 col-md-12" data-aos="fade-up" data-aos-duration="700">
                <h2 className="h1">Get Started in 3 Steps</h2> 
            </div>
        </div>
        <div className="row get-start-wrapper" data-aos="fade-up" data-aos-duration="900">
            <div className="col-lg-5" style={{paddingRight: '0px'}}>
                <div ref={(el) => divRefs.current.push(el)}
                    onMouseEnter={() => handleMouseEnter(1)}
                    className={activeIndex === 1 ? 'mt-5 active' : 'mt-5 '}>
                    <h3>Create your own experience step 1</h3>
                </div>
                <div ref={(el) => divRefs.current.push(el)}
                    onMouseEnter={() => handleMouseEnter(2)}
                    className={activeIndex === 2 ? 'active' : ''}>
                    <h3>Create your own experience</h3>
                </div>
                <div ref={(el) => divRefs.current.push(el)}
                    onMouseEnter={() => handleMouseEnter(3)}
                    className={activeIndex === 3 ? 'mb-5 active' : 'mb-5 '}>
                    <h3>Create your own experience</h3>
                </div>

            </div>
            <div className="col-lg-7" style={{paddingLeft: '0px'}}>
                <div className="inner-content-head get-start-video" data-aos="fade-up" data-aos-duration="1100">
                    <div className="inner-content">
                        <div className="intro-video-play">
                            <div className="">{/* play-thumb */}
                                <img src={`assets/images/get-start/${activeIndex}.jpg`} alt={`step ${activeIndex}`}/>
                                <a href="https://www.youtube.com/watch?v=EZyAJ79PTZM"
                                    // className="glightbox video"><i className="fa fa-play"></i>
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default GetStart