import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bnbDataSelector, btcDataSelector, ethDataSelector, loadPrices, lqrDataSelector } from '../../../../Redux/livePriceSlice';

function LivePrice({ btc, eth, bnb, lqr, background, dispatch }) {
  useEffect(() => {
    loadCryptoPrices();
  }, []);

  const loadCryptoPrices = () => {
    loadPrices(dispatch);
  };

  return (
    <section className={`section live-price ${background}`} id="prices">
      <div className="container">
        <div className="row mb-5">
          <div className="col-xl-7 col-lg-10 col-md-12 title-wrapper" data-aos="fade-up" data-aos-duration="700">
            <h2 className="h1">
              Popular <span className="text-primary">cryptocurrencies</span>
            </h2>
            <a href="!#">
              View more markets
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z" fill="currentColor"></path>
              </svg>
            </a>
          </div>
        </div>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope='col'>Name</th>
              <th scope='col'>Last Price</th>
              <th scope='col'>24h Change</th>
              <th scope='col'>Market Cap</th>
            </tr>
          </thead>
          <tbody>
            <tr data-aos="fade-up" data-aos-duration="700">
              <th scope='row'>
                <img src='assets/images/tokens/BTC.png' alt="" />
                Bitcoin<span>BTC</span>
              </th>
              <td className={btc.priceColor ? 'Green' : 'Red'}>{(btc.price.toLocaleString()) + (btc.priceColor ? ' 🠩' : ' 🠫')}</td>
              <td className={btc.priceChange > 0 ? 'Green' : 'Red'}>{(btc.priceChange > 0 ? '+' : '') + (btc.priceChange + "%")}</td>
              <td className="Black">{btc.marketcap}</td>
            </tr>
            <tr data-aos="fade-up" data-aos-duration="900">
              <th scope='row'>
                <img src='assets/images/tokens/ETH.png' alt="" />
                Ethereum<span>ETH</span>
              </th>
              <td className={eth.priceColor ? 'Green' : 'Red'}>{(eth.price.toLocaleString()) + (eth.priceColor ? ' 🠩' : ' 🠫')}</td>
              <td className={eth.priceChange > 0 ? 'Green' : 'Red'}>{(eth.priceChange > 0 ? '+' : '') + (eth.priceChange + "%")}</td>
              <td className="Black">{eth.marketcap}</td>
            </tr>
            <tr data-aos="fade-up" data-aos-duration="1100">
              <th scope='row'>
                <img src='assets/images/tokens/BNB.png' alt="" />
                BNB<span>BNB</span>
              </th>
              <td className={bnb.priceColor ? 'Green' : 'Red'}>{(bnb.price.toLocaleString()) + (bnb.priceColor ? ' 🠩' : ' 🠫')}</td>
              <td className={bnb.priceChange > 0 ? 'Green' : 'Red'}>{(bnb.priceChange > 0 ? '+' : '') + (bnb.priceChange + "%")}</td>
              <td className="Black">{bnb.marketcap}</td>
            </tr>
            <tr data-aos="fade-up" data-aos-duration="1300">
              <th scope='row'>
                <img src='assets/images/tokens/LQR.png' alt="" />
                Laqira<span>LQR</span>
              </th>
              <td className={lqr.priceColor ? 'Green' : 'Red'}>{(lqr.price.toLocaleString()) + (lqr.priceColor ? ' 🠩' : ' 🠫')}</td>
              <td className={lqr.priceChange > 0 ? 'Green' : 'Red'}>{(lqr.priceChange > 0 ? '+' : '') + (lqr.priceChange + "%")}</td>
              <td className="Black">{lqr.marketcap}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

function mapStateToProps(state) {
  return {
    btc: btcDataSelector(state),
    eth: ethDataSelector(state),
    bnb: bnbDataSelector(state),
    lqr: lqrDataSelector(state),
  };
}

export default connect(mapStateToProps)(LivePrice);
